.btn {
  border: 0;
  padding: 0;
  background-color: var(--base-black);
  color: var(--secondary-white);
  font-family: var(--font-medium);
  letter-spacing: -0.04em;
  line-height: 31px;
  padding: 11px 0;
  font-size: 1.55555rem;
  display: inline-block;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  &.-huge-text {
    font-size: 1.2rem;
  }

  &:hover, &:active {
    background-color: var(--secondary-red);
  }

  &:disabled {
    opacity: .5;
    pointer-events: none;
  }

  &.-small {
    width: 40%;
  }

  &.-tiny {
    width: 20%;
  }

  &.-tiny-inside {
    width: 8.88rem;
    font-size: 1.11rem;
    line-height: 1.66rem;
  }

  &.-action {
    font-size: 0.94rem;
    background-color: var(--base-black);
    padding: 0.5rem 1.22rem 0.55rem 1.22rem;
    font-weight: 700;
  }

  &.-link {
    text-decoration: none;
    align-self: center;

    &:hover {
      background-color: var(--base-red) !important;
    }
  }

  &.-social {
    background-color: var(--base-brown);
    width: 60px;
    height: 48px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--base-black) !important;
    }
  }

  &.-social-top {
    background-color: var(--base-brown);
    width: 40px;
    height: 36px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--base-black) !important;
    }
  }
}