@import "_legal";

/**
 * base/_main.scss
 */
html {
  scroll-behavior: smooth;
}

body {
  display: grid;
  background-color: var(--base-peach);

  .hero,
  .main,
  .footer {
    width: 100vw;
    overflow: hidden;
  }

  .video-background {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: "Header Navigation""Hero Hero""Main Main""Footer Footer";

    width: 100%;
    margin: 0 auto;

    .video-background {
      display: inherit;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      z-index: -100;
    }
    
    .fullscreen-background {
      position: absolute;
      top: 0;
      // left: 10%;
      transform: translateX(-100%);
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: top;
    }

    .navigation {
      grid-area: Navigation;
    }

    .header {
      grid-area: Header;
    }

    .hero {
      grid-area: Hero;
    }

    .main {
      grid-area: Main;
      position: relative;
      background-color: var(--base-pink);
    }

    .footer {
      grid-area: Footer;
    }

    .hero,
    .main,
    .footer {
      width: 100%;
    }
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

h2,
h3,
h4 {
  width: 58%;
  position: relative;

  mark {
    height: 0.1em;
    display: inline-block;
    background-color: var(--secondary-green);
    background-position-y: 50%;
  }
}

h2,
h3 {
  font-family: var(--font-bold);
  font-weight: 700;
}

h1 {
  font-size: 3.8rem;
}

h2 {
  font-size: 2.77rem;
  line-height: get-line-height(50, 39);
  letter-spacing: -0.05em;
}

h3 {
  font-size: 1.98rem;
  letter-spacing: -0.05em;
  line-height: get-line-height(30, 30);
  margin-bottom: 1.88rem;
  margin-top: 0.16rem;
  margin-left: -0.3rem;
}

h4 {
  font-family: var(--font-medium);
  font-weight: 500;
  font-size: 1.11rem;
}

p,
label,
small {
  font-family: var(--font-light);
  font-weight: 300;
  line-height: get-line-height(20, 35);
  letter-spacing: -0.04em;
  margin: 0;
}

a {
  color: var(--base-black);

  // &:hover {
  //   color: var(--secondary-red);
  // }
}

span {
  font-weight: 500;
}

@media only screen and (min-width: 768px) {
  h2 {
    font-size: 5.875rem;
    line-height: get-line-height(94, 75);
  }

  p {
    font-size: 1rem;
    letter-spacing: -0.06em;
  }
}