.btn {
  outline: none;
  transition: background-color .35s;
}

a {
  transition: color .3s;
}

.step {
  transition: height .35s ease-in-out;
}

@media screen and (min-width: 720px) {
  .header > h1, .navigation > ul, hero-desktop > header  {
    transform: translateY(-35px);
    opacity: 0;
  }

  body {
    overflow: hidden;
  }
  
  .main, footer {
    opacity: 0;
  }
  
  .video-background video {
    transform: translateX(100vw);
  }
  
  .hero-desktop {
    height: 120%;
    .paragraph-hero, #cadastre-hero, #form-hero {
      opacity: 0;
      transform: translateY(35px);
    }
  
    $lines: "#linha1", "#linha2", "#linha3";
    
    @each $line in $lines {
      #{$line} {
        opacity: 0;
        > g {
          rect {
            width: 0
          }
        }
      }
    }
  }
}
