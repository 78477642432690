input[type=email], input[type=text], input[type=number], input[type=tel] {
  width: 100%;
  border: 0;
  border: 1px solid var(--base-gray);
  padding: 1.02rem 1.16rem 0.88rem 1.16rem;
  border-radius: 10px;
  -webkit-appearance: none;
  font-family: var(--font-light);
  color: var(--base-gray);

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-family: var(--font-light);
    font-weight: 300;
    color: var(--base-black);
  }

  &.-error {
    border-color: var(--secondary-red);
  }

  &:read-only {
    background-color: #D78EAA;
    border-color: #D78EAA;
    opacity: 0.7;
   }
   
  //  &.-readonly::placeholder {
  //    color: var(--secondary-white);
  //  }

   &.-tiny {
     width: 23%;
     margin-left: 0.55rem;
   }

   &.-small {
     width: 45%;
     margin-left: 0.55rem;
   }
}