.ondetem {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-top: 3.05rem;
  color: var(--base-gray);
  .info {
    padding: 0 1.2rem;
    header {
      form {
        display: flex;
        flex-direction: column;
    
        .-submit {
          padding: 0;
          padding: 0.61rem 0.66rem 0.44rem 0.66rem;
          border: 0;
          align-self: flex-end;
          font-size: 0.94rem;
          margin-top: 1.16rem;
        }
      }
    }
  
    .content {
      display: flex;
      flex-direction: column;
      padding-top: 1.277rem;
    }
  }

  .map {
    margin-top: 2.72rem;
    height: 500px;
    position: relative;
    > iframe {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

@media only screen and (min-width: 768px) {
  .ondetem {
    flex-direction: row;
    padding: 0;
    .info  {
      width: 40%;
      padding-top: 10.75rem;
      padding-left: 8.6875rem;
      padding-right: 5.75rem;
      padding-bottom: 8.6875rem;
      
      header, .content {
        width: 100%;
      }

      .content {
        margin-top: 4.125rem;
      }
    }
    .map {
      position: relative;
      margin-top: 0;
      width: 60%;
      height: auto;

      .overlay-map {
        // background-color: rgba(0, 0, 0, .3);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; 
        z-index: 1;
        cursor: pointer;

        &::after {
          content: 'Clique para interagir com o mapa.';
          width: 100%;
          text-align: center;
          color: var(--secondary-green);
          font-family: var(--font-medium);
          font-size: 1rem;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(var(--base-black-rgb), .75);
        }
      } 

      iframe {
        pointer-events: none;
        z-index: 0;
      }
    }
  }
}