.legal {
  .main {
    padding: 2.27rem 1.33rem;

    header {
      h4 {
        margin-top: 2.83rem;
        font-size: 1.55rem;
        text-align: justify;
        line-height: get-line-height(28, 33);
      }
    }

    .content {
      .paragraph:first-child {
        margin-top: 0.33rem;
      }
      .paragraph:last-child {
        margin-bottom: 2.83rem;
      }
    }
  }
}