.navigation {
  width: 100vw;
  background-color: var(--base-pink);
  background-color: transparent;
  display: flex;
  position: relative;
  padding: 1rem 0 1rem 1.1111rem;
  
  .dots-after, .dots-before {
    position: absolute;
    width: 90px;
    padding: 1px 0;
    display: table-cell;
    margin-top: 6px;
    background: rgb(253,180,211);
    background: -moz-linear-gradient(-270deg, rgba(253,180,211,0) 0%, rgba(253,180,211,1) 59%);
    background: -webkit-linear-gradient(-270deg, rgba(253,180,211,0) 0%, rgba(253,180,211,1) 59%);
    background: linear-gradient(-270deg, rgba(253,180,211,0) 0%, rgba(253,180,211,1) 59%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdb4d3",endColorstr="#fdb4d3",GradientType=1);
    right: 0;
    z-index: 1;
    font-size: 1rem;
    font-weight: 700;
    color: var(--base-brown);
    text-indent: 55px;
  }

  .dots-before {
    left: 0;
    transform: scaleX(-1);
    display: none;
  }

  ul {
    display: flex;
    width: auto;
    overflow-x: auto;
    padding: 0;
    margin: 0;
    position: relative;
    flex-flow: row;
    align-items: center;

    &::-webkit-scrollbar {
      display: none; 
    } 
    
    li {
      list-style: none;
      margin-right: 2rem;
      &:last-child {
        padding-right: .55rem;
      }
    }
    
    li.last-social {
      margin-right: 80px;
      @media only screen and (min-width: 768px) {
        margin-right: 2rem;
      }
    }

    li.placeholder-li {
      height: 1px;
      width: 1px;
    }

    li a {
      padding: 0.02rem;
      font-family: var(--font-medium);
      display: block;
      white-space: nowrap;
      text-decoration: none;
      color: var(--base-brown);

      &:hover {
        color: var(--secondary-white);
        text-decoration: underline;
      }
    }
  }
}

/* LEGAL */
.legal {
  .navigation {
    background-color: var(--secondary-white);
  }
}

@media only screen and (min-width: 768px) {
   .navigation {
    .dots-before, .dots-after { display: none; }
     width: 100%;
     align-items: center;
     ul {
       margin-left: 8%;
     }
   }
}

