.produtos {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--base-peach);
  justify-content: space-between;
  position: relative;
  
  .content {
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    
    h3, p {
      color: var(--base-brown);
    }

    h3 {
      width: 100%;
      text-align: center;
      margin-top: 4.44rem;
    }
  }

  .grid {
    display: grid;
    width: 100%;
    max-width: 60.44rem;
    margin-top: 3rem;
    grid-template-columns: 1fr;
    
    .item{
      margin-bottom: 6rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      h4{
        margin-top: 2.22rem;
      }

      a {
        position: relative;
        width: 2.77rem;
        height: 2.77rem;
        display: block;
        cursor: pointer;
        margin-top: 1.66rem;

        &:after {
          content: ' ';
          display: block;
          background-color: var(--base-black);
          border-radius: 50%;
          position: absolute;
          z-index: -1;
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          top: 0;
          transition: transform cubic-bezier(0.390, 0.575, 0.565, 1.000);
          animation: highlight 1s infinite;
          animation-fill-mode: forwards;
        }

        &.-diurno:after {
          border: 1px solid #A1C925;
          background-color: #A1C925;
        }

        &.-semabas:after {
          border: 1px solid #0068B4;
          background-color: #0068B4;
        }
        
        &:hover:after {
          border: 1px solid var(--base-pink);
          background-color: var(--base-pink);
        }

        svg {
          circle {
            transition: all .35s;
          }
        }

        &:hover svg {
          
          circle {
          fill: var(--secondary-red);
          }

        }
      }
    }

    .item-com-abas{
      max-width: 16.94rem;
    }

    .item-noturno{
      max-width: 16.66rem;
    }

    .item-sem-abas{
      max-width: 17rem;
    }
  }

  .product {
    width: 100%;
    display: block;
  }

  h4 {
    width: 100%;
    font-weight: 700;
    color: var(--base-brown);
    text-align: center;
  }
  
}

.overlay
{
  pointer-events: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 10000;

  .overlay__item {

    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--base-peach);
    opacity: 0;
    z-index: 0;
  
    .box {
  
      width: 100%;
      height: 100vh;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      overflow-y: scroll;
    }
  
    .image {

      justify-self: center;
      max-width: 16.9rem;
      margin-top: 2.22rem;
      display: block;
    }
  
    .info {
      
      margin-top: 2rem;
      margin-bottom: 4rem;
      
    }
  
    .box_title {
      width: 100%;
      color: var(--base-brown);
      margin-bottom: 1rem;
    }
  
    .box_small {
      font-size: .77rem;
      width: 100%;
      display: block;
    }
  
    .box_text {
      margin-top: 1.67rem;
      width: 100%;
    }
  
  }
  
  .overlay__close {
    cursor: pointer;
  }
  
  // .overlay__close:focus {
  //   outline: none;
  // }
  
  .overlay__content {
    font-size: 1rem;
    line-height: 1.5;
    max-width: 25rem;
    margin: 1rem 0 0 10vw;
  }

}

.overlay--open {
  pointer-events: auto !important;
}

.preview-open {
	overflow: hidden !important;
}

.overlay__reveal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--base-pink);
  position: absolute;
  z-index: 100;
  transform: translate3d(100%,0,0);
}

@media only screen and (min-width: 768px) {


  .produtos {
    min-height: 777px;

    .grid {
      margin-top: 4.44rem;
      grid-template-columns: 1fr 1fr 1fr;

      .item {
        margin-bottom: 0rem;
      }
    }
  }

  .overlay
  {
    .overlay__item {

      .box {
        padding: 4rem;
        grid-template-columns: 1fr 5fr 7fr;
      }

      .image {
        margin-top: 0;
      }

      .info {
        margin-top: 0;
      }

      .box_title {
        max-width: 21rem;
      }

      .box_text {
        max-width: 19.55rem;
      }

      .box_small {
        max-width: 12.5rem;
      }
    }
  }
}

@keyframes highlight {
  0%   { 
    opacity: 1; 
    transform: scale(1);
  }
  100% { 
    opacity: 0;
    transform: scale(2);
  }
}