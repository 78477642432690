.footer {
  display: flex;
  background-color: var(--base-peach);
  flex-direction: column;
  
  header {
    display: flex;
    flex-direction: column;
    padding: 3rem 0 0 1rem;
    width: 100%;
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url('../../assets/images/mobile/modelo-II.jpg');
    background-image: -webkit-image-set(
      url('../../assets/images/mobile/modelo-II.jpg') 1x,
      url('../../assets/images/mobile/modelo-II@2x.jpg') 2x
    );
    background-image: image-set(
      url('../../assets/images/mobile/modelo-II.jpg') 1x,
      url('../../assets/images/mobile/modelo-II@2x.jpg') 2x
    );

    h2 {
      color: var(--secondary-white);
      font-size: 3.8888rem;
      line-height: get-line-height(65, 50);
      width: 10%;
      span {
        color: var(--base-peach);
      }
    }
  }

  .content {
    padding: 3rem 1.33rem;
    display: flex;
    flex-direction: column;

    .btn {
      background-color: var(--base-brown);

      &:hover {
        background-color: var(--base-black);
      }
    }

    h3 {
      color: var(--base-brown);
      line-height: get-line-height(30, 40);
    }

    h4 {
      font-size: 1rem;
    }
  
    .paragraph {
      color: var(--base-brown);
      text-align: center;
    }

    .-link {
      text-align: center;
      margin: 1rem 0;
    }

    .social {
      display: flex;
      align-self: center;
      .-link {
        margin-left: 0.72rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    header {
      min-height: 100vh;
      background-position: top;
      background-image: url('../../assets/images/desktop/modelo-II.jpg');
      background-image: -webkit-image-set(
        url('../../assets/images/desktop/modelo-II.jpg') 1x,
        url('../../assets/images/desktop/modelo-II@2x.jpg') 2x
      );

      background-image: image-set(
        url('../../assets/images/desktop/modelo-II.jpg') 1x,
        url('../../assets/images/desktop/modelo-II@2x.jpg') 2x
      );

      h2 {
        font-size: 9.3125rem;
        // margin-left: 2.6875rem;
        // margin-top: 4.6875rem;
        margin-left: 3%;
        margin-top: 5%;
      }
    }

    .content {
      width: 50%;
      display: flex;
      padding: 0;
      padding-right: 9.5625rem;
      justify-content: center;
      align-items: center;
      h4 {
        width: 100%;
        font-size: 1.875rem;
        color: var(--base-brown);
        font-weight: 500;

        &.-small {
          font-size: 1.125rem;
        }
      }

      .btn {
        font-weight: 500;
        font-size: 1.5rem;
        margin-top: 3.25rem;
        margin-bottom: 3.25rem;
      }
    }

  }
}