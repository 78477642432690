:root {
  --base-pink: #FDB4D3;
  // --base-pink: #FCA7C8;
  --base-black: #151518;
  --base-black-rgb: 21, 21, 24;
  --base-brown: #564741;
  --base-peach: #FFDFD4;
  --base-blue: #BEE1E9;
  --base-gray: #5F5F5F;

  --secondary-green: #E4FE05;
  --secondary-white: #FFFFFF;
  --secondary-purple: #9C549E;
  --secondary-red: #D70071;
  --secondary-gray: #ADADAD;
  --secondary-blue: #B6D9DF;
  --secondary-light-gray: rgba(0,0,0 0.1);
}