/**
 * settings/_main.scss
 */

/* ==========================================================================
   Imports
   ========================================================================== */

// @import "../../../../../node_modules/rfs/scss";
@import "_colors";
@import "_animations";

:root {
  
  --font-bold: 'Poppins', sans-serif;
  --font-medium: 'Poppins', sans-serif;
  --font-light: 'Poppins', sans-serif;

  --base-font-size: 18;
  
  font-size: 0.9em;

  @media screen and (min-width: 321px) {
    font-size: 1.125em;
  }

  // @media screen and (min-width: 768px) {
  //   font-size: 1em;
  // }
  
}

html {
  color: var(--base-black);
}