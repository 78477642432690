.perguntas-frequentes {
  background-color: var(--base-blue);
  padding: 2.33rem 1.33rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .content {
    .list {
      padding: 0;
      list-style: none;
      counter-reset: faq-counter;

      .item {
        counter-increment: faq-counter;
        padding: 1.333rem 0 1.333rem .5rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 1rem;
        cursor: pointer;
        transition: background-color .3s;

        &:hover {
          background-color: var(--secondary-blue);
        }
        
        header {
          display: flex;
          h4 {
            flex: 1;
          } 
        }

        .content {
          > ul {
            > li {
              padding-left: 0 !important;
              margin-left: 0;
              font-family: var(--font-medium);
              font-size: 0.888rem;
              line-height: get-line-height(16, 24);
              padding: 0 1.33rem;
              list-style: square;  
            }
          }
        }

        &.-opened {
          background-color: var(--secondary-blue);
          .content {
            display: block;
          }
        }

        .paragraph {
          font-size: 0.888rem;
          line-height: get-line-height(16, 30);
          padding: 1.33rem;
        }

        &::before {
          content: counter(faq-counter) " .";
          font-size: 1rem;
          padding-top: 0.1rem;
          padding-right: 0.2rem;
          font-family: var(--font-medium);
          float: left;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }

  .list {
    padding: 0;
    list-style: none;
    counter-reset: faq-counter;

    .item {
      counter-increment: faq-counter;
      padding: 1.333rem 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 1rem;

      header {
        display: flex;

        h4 {
          flex: 1;
        }

        .arrow {
          width: 10%;
          background-color: transparent;
          align-self: flex-start;
        }
      }

      .content {
        display: none;

        &.-opened {
          display: block;
        }
      }

      .paragraph {
        font-size: 0.888rem;
        line-height: get-line-height(16, 24);
        padding: 0 1.33rem;
      }

      &::before {
        content: counter(faq-counter) " .";
        font-size: 1rem;
        padding-top: 0.1rem;
        padding-right: 0.2rem;
        font-family: var(--font-medium);
        float: left;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .perguntas-frequentes {
    flex-direction: row;
    justify-content: space-between;
    position: sticky;

    padding-top: 6.5rem;
    padding-left: 5.2875rem;

    > header {
      width: 20%;

      h4 {
        width: 100%;
      }
    }

    .content {
      width: 60%;

      .list {
        padding-top: 0;
        margin-top: 0;

        .item {
          padding: 2rem 0;
          padding-left: 1rem;
          
          header {
            h4 {
              font-size: 1.25rem;
            }
            .arrow {
              width: 5%;
            }
          }

          .content {
            > ul {
              padding-left: 2.125rem; 
            }
          }

          .content {
            width: 100%;
            .paragraph {
              font-size: 1rem;
            }
          }
        }
      }

      .list .item {
        width: 80%;
      }

    }
  }
}