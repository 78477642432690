.hero-desktop {
  display: none;
  visibility: hidden;
}

.hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: transparent;
  padding: 0 1.3333rem 1.111rem 1.3333rem ;
  position: relative;

  h2 {
    position: relative;
    width: 58%;
    z-index: 1;
  }

  .product {
    width: 100%;
    margin-top: -70px;
    position: relative;
    z-index: 0;
    img {
      width: 80%;
      margin: 0 auto;
    }
  }
  .paragraph {
    width: 268px;
    margin: -35px 0 10px 0;
    position: relative;
    align-self: center;
    line-height: get-line-height(18,24);
    letter-spacing: -0.02;
  }

}

@media only screen and (min-width: 768px) {
  .hero {
    display: none;
    visibility: hidden;
  }

  .hero-desktop {
    grid-area: Hero;
    display: flex;
    visibility: visible;
    position: relative;
    background-color: transparent;
    width: 100%;
    min-height: 80vh;
    align-items: center;
    
    header {
      padding: 0 0 0 8vw;
      width: 50%;
      margin-bottom: 7%;
      
      h2 {
        width: 70%;
      }

      .paragraph {
        width: 80%;
        margin-top: 1rem;
      }
      .btn {
        width: 80%;
        margin-top: 2rem;
      }
    }

    .product {
      height: 100%;
      padding-left: 6rem;
      padding-bottom: 2rem;
    }

    form {
    
      margin-top: 1.6rem;
      display: flex;
      flex-direction: column;
      width: 80%;

      .step2{
        margin-bottom: 3rem;
      }
  
      span {
        margin-left: 2%;
        margin-top: 0.35rem;
        font-family: var(--font-medium);
        color: var(--secondary-white);
        font-size: 0.65rem;
        
        &.-error {
          height: 11px;
          color: var(--secondary-red);
        }
      }
      
    }
  }
}