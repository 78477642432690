.eumeconheco {
  display: flex;
  background-color: var(--base-black);
  flex-direction: column;
  
  header {
    padding: 3rem 0 0 1rem;
    width: 100%;
    height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    background-image: url('../../assets/images/mobile/modelo-I.jpg');
    background-image: -webkit-image-set(
      url('../../assets/images/mobile/modelo-I.jpg') 1x,
      url('../../assets/images/mobile/modelo-I@2x.jpg') 2x
    );
    background-image: image-set(
      url('../../assets/images/mobile/modelo-I.jpg') 1x,
      url('../../assets/images/mobile/modelo-I@2x.jpg') 2x
    );

    h2 {
      color: var(--secondary-white);
      font-size: 3.8888rem;
      width: 10%;
      span {
        color: var(--base-blue);
      }
    }
  }

  .content {
    padding: 3rem 1.33rem 3.88rem 1.33rem;

    h3 {
      color: var(--secondary-white);
      width: 110%;
    }
  
    .paragraph {
      color: var(--secondary-white);
      margin-top: 2rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .eumeconheco {
    min-height: 100vh;
    padding: 3rem 0;
    flex-direction: row;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-image: url('../../assets/images/desktop/modelo-I.jpg');
    background-image: -webkit-image-set(
      url('../../assets/images/desktop/modelo-I.jpg') 1x,
      url('../../assets/images/desktop/modelo-I@2x.jpg') 2x
    );
    background-image: image-set(
      url('../../assets/images/desktop/modelo-I.jpg') 1x,
      url('../../assets/images/desktop/modelo-I@2x.jpg') 2x
    );

    header {
      background: transparent;
      padding-top: 6.25rem;
      padding-left: 5.2875rem;
      
      h2 {
        font-size: 10.785rem;
        line-height: get-line-height(171, 105);
      }
    }

    .content {
      background: transparent;
      margin-top: 15.75rem;
      margin-left: 4rem;
      
      h3, p {
        width: 80%;
      }
    }
  }
}