.novidades {
  display: flex;
  flex-direction: column;
  background-color: var(--base-pink);
  overflow: hidden;
  padding-top: 1.6111rem;
  padding-left: 1.381rem;
  padding-right: 1.381rem;
  padding-bottom: 7.33rem;
  
  header {
    width: 100%;
    display: flex;
    flex-direction: column;
    
    p {
      padding-bottom: 2rem;
      color: var(--base-brown);
    }
    
    h3 {
      max-width: 19.875rem;
    }
    
  }
  
  form {
    
    margin-top: 0rem;
    display: flex;
    flex-direction: column;

    span {
      margin-left: 2%;
      margin-top: 0.35rem;
      font-family: var(--font-medium);
      color: var(--secondary-white);
      font-size: 0.65rem;
      
      &.-error {
        height: 11px;
        color: var(--secondary-red);
      }
    }
    
  }
  
  
}

@media only screen and (min-width: 768px) {
  
  .novidades {
    width: 1366px;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 8.375rem;
    padding-right: 8.375rem;
    
    header {
      display: grid;
      grid-template-columns: 5fr 7fr;
      
      h3 {
        max-width: 19.875rem;
      }
      
      .paragraph {
        width: 26.75rem;
        font-size: 1.625rem;
        line-height: 1.875rem;
      }
    }
    
    form {
      
      margin-top: 0rem;
      
      display: grid;
      grid-template-columns: 5fr 7fr;
      
      grid-template-areas: ". form-newsletter";
      
    }
  }
  
  
  
}