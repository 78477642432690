input[type=checkbox] {
	opacity: 0;
}

	input[type=checkbox].slide-checkbox+label {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-top: 0;
  margin-left: -0.7rem !important;
	text-indent: 3.5rem;
	cursor: pointer;
	}

input[type=checkbox].slide-checkbox+label:before {
	content: "";
	width: 54px;
	height: 32px;
	position: absolute;
	background-color: var(--base-brown);
	border-radius: 16px;
	top: 0;
	left: 0;
	transition: all 0.25s ease-in-out;
}

input[type=checkbox].slide-checkbox+label:after {
	content: "";
	width: 28px;
	height: 28px;
	top: 2px;
	left: 2px;
	position: absolute;
	background: #fafafa;
	border-radius: 50%;
	box-shadow: 0 2px 7px -2px rgba(0, 0, 0, 0.4), 1px 2px 1px rgba(0, 0, 0, 0.2);
	transition: all 0.25s ease-in-out;
}

input[type=checkbox].slide-checkbox:checked+label:before {
   background-color: var(--secondary-green);
}

input[type=checkbox].slide-checkbox:checked+label:after {
	left: 24px;
}

/* Focus */

input[type=checkbox].slide-checkbox:focus+label:before {
    border-color: #957adb;
    outline: 0;
    box-shadow: 0 0 0 3px rgba(52, 152, 219,0.5);
}

// /* Validation for angular.js*/

// form.ng-submitted input[type="checkbox"].slide-checkbox.ng-invalid+label:before {
//     background-color: #ff89a3;
// }
