.form-newsletter {
  
  >.steps {
    
    >.step {
      
      grid-area: form-newsletter;
      
      &.-step1 {

        max-width: 29.8rem;

        .field {
          position: relative;
        }
        
        .btn {
          float: right;
          margin-top: 1.11rem;
          // position: absolute;
          // top: .138rem;
          // right: .138rem;
        }
      }

      &.-step2{

        display: flex;
        flex-direction: column;
        width: 100%;

        .fields {
          background-color: var(--base-black);

          .field {
            padding: 2rem;
            color: var(--secondary-white);

            &.-text {
              padding-top: 0;
              color: var(--secondary-white);
              flex-direction: column;
            }
          }
        }
        
      }
    
    }
    
  }
}



@media only screen and (min-width: 768px) {
  
  .form-newsletter {
    
    >.steps {
      
      
      grid-area: form-newsletter;
      
      >.step {
        
        &.-step1 {

          max-width: 29.8rem;
          
          .field {
            position: relative;

            input[type=email] {
              padding-right: 10rem;
            }
          }
          
          .btn{
            position: absolute;
            top: .16rem;
            right: .138rem;
            margin-top: auto;
          }

          .btn-top-header{
            top: .1278rem;
            width: 8.88rem;
          }

        }
        
      }
      
    }
  }
  
  
  
}