.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--base-pink);
  background-color: transparent;
  
  h1 {
    align-self: center;
    margin-top: 1.111rem;
  }
}

.legal {
  .header {
    background-color: var(--secondary-white);
  }
}

@media only screen and (min-width: 768px) {
  .header {
    flex-direction: row;
    padding: 0 0 0 8vw;
  }
}