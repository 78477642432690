/**
 * tools/_main.scss
 */

/* ==========================================================================
   Imports
   ========================================================================== */

/* CALC LINE-HEIGHT */
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}

// /* CALC REM UNIT */
// @function responsiveUnit($unit) {
//   @return $unit / 18;
// }

// @function letterSpacing($size) {
//   @return $size / 100;
// }

// @function number($string) {
//   // Matrices
//   $strings: '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
//   $numbers: 0 1 2 3 4 5 6 7 8 9;

//   // Result
//   $result: 0;

//   // Looping through all characters
//   @for $i from 1 through str-length($string) {
//     // Do magic
//   }

//   @return $result;
// }
