.participe {
  display: flex;
  flex-direction: column;
  background-color: var(--base-pink);
  overflow: hidden;
  padding-top: 1.6111rem;
  padding-left: 1.381rem;

  header {
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  .content {
    .progress-bar {
      position: relative;

      hr {
        width: 400vw;
        border: 0;
        border-top: 1px solid var(--secondary-white);
      }

      .ball {
        position: absolute;
        width: 1rem;
        height: 1rem;
        background-color: var(--base-black);
        border-radius: 1rem;
        top: -6px;
        left: 0%;
      }
    }

    #form {
      >.steps {
        display: flex;

        >.step {
          &.-inactive {
            display: none;
            visibility: hidden;
          }
        }

        .step .fields {
          width: 93vw;
        }
        .step3 .fields {
          margin-top: 0.7777rem;
        }

        .step4 {
          display: flex;
          flex-direction: column;

          .fields {
            background-color: var(--base-black);

            .field {
              padding: 2rem;
              color: var(--secondary-white);

              &.-text {
                padding-top: 0;
                color: var(--secondary-white);
                flex-direction: column;
              }
            }
          }
        }

        .fields .field {
          display: flex;
          margin-top: 0.75rem;
          margin-bottom: 0.75rem;
          margin-left: 0;
          margin-right: 1.5rem;

          .btn {
            margin-top: 1rem;
            font-size: 1rem;
          }

          span {
            margin-left: 2%;
            margin-top: 0.35rem;
            font-family: var(--font-medium);
            color: var(--secondary-white);
            font-size: 0.65rem;

            &.-error {
              height: 11px;
              color: var(--secondary-red);
            }
          }

          &.-inline {
            flex-direction: column;
          }

          label {
            width: 80%;
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .participe {
    width: 1366px;
    padding-top: 5rem;
    padding-bottom: 7.5rem;
    padding-left: 8.375rem;
    padding-right: 8.375rem;

    header {
      flex-direction: row;
      display: flex;

      .paragraph {
        font-size: 1.625rem;
        margin-left: 6.1875rem;
      }
    }

    .content {
      // display: flex;
      .progress-bar {
        margin-top: 2.85rem;
        .ball  {
          left: 2rem;
        }
      }

      .progress,
      #form .steps .step header h3,
      .steps .step header .paragraph {
        margin-left: 2rem;
      }

      #form {
        .steps {
          flex-wrap: nowrap;
          flex-grow: 1;

          .step .fields {
            width: 90%;
          }

          .step {
            width: 100%;
            flex: 1 0 auto;
            display: flex;
            justify-content: space-between;
            header {
              display: flex;
              flex-direction: column;
              h3 {
                margin-left: 22px;
              }
              .paragraph {
                width: 40%;
                margin-top: 0;
              }
            }
          }
          .step4 {
            flex-direction: row;
            p.paragraph {
              margin-bottom: 1rem;
              strong {
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
}