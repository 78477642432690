/**
 * vendor/_main.scss
 */

/* ==========================================================================
   Third-party styles
   ========================================================================== */

// @import "";
@import "normalize";

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}